<template>
    <painel titulo="Parâmetros" icone="pi pi-copy" :refreshFunction="obterParametro">
        <div class="mb-4">
            <detalhe titulo="Código" :size="sizeForm">{{ parametro?.codigo }}</detalhe>
            <detalhe titulo="Descrição" :size="sizeForm">{{ parametro?.descricao }}</detalhe>
            <detalhe titulo="Detalhe" :size="sizeForm">{{ parametro?.detalhe }}</detalhe>
            <detalhe titulo="Valor" :size="sizeForm">
                <vue-json-pretty
                    :data="JSON.parse(parametro?.valor)"
                    :showDoubleQuotes="true"
                    v-if="parametro && parametro.valor && isJsonString(parametro.valor)"
                ></vue-json-pretty>
                <span v-if="parametro && parametro.valor && !isJsonString(parametro.valor)">{{ parametro?.valor }}</span>
            </detalhe>
        </div>
        <btn-atualizar :parametro="parametro"></btn-atualizar>
        <btn-inserir class="ml-2"></btn-inserir>
        <btn-voltar
            :route="{
                name: `Parametros`,
            }"
        ></btn-voltar>
    </painel>
</template>

<script>
import ParametrosService from './services';
import BtnInserir from './BtnInserir';
import BtnAtualizar from './BtnAtualizar';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components: {
        BtnInserir,
        BtnAtualizar,
        VueJsonPretty,
    },

    data() {
        return {
            parametro: null,
        };
    },

    computed: {
        sizeForm() {
            return '100';
        },
    },

    methods: {
        obterParametro() {
            this.$store.dispatch('addRequest');
            ParametrosService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.parametro = response.data;
                } else {
                    this.parametro = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
    },

    mounted() {
        this.obterParametro();
    },
};
</script>
